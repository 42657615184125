import { Box, Container, Flex, Heading, Text } from "@chakra-ui/react"

interface IPageSection {
  children?: any,
  alignHeading?: string,
  backgroundImage?: string,
  heading?: string,
  id?: string,
  fullWidth?: string | boolean,
  paragraphs?: string[]
}

const SectionHeading = ({ text }) => <Heading
  background='white'
  p={8}
  textAlign='center'
  as="h2"
  fontFamily='Brilon'
  fontWeight={400}
  fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
  zIndex='10'
  textColor="#111"
  transform='translateY(30px)'
  borderRadius={10}
  border='3px solid black'
  boxShadow='0 0 5px #000'
  >
    { text }
  </Heading>

const PageSection = ({ children, backgroundImage, fullWidth = false, heading, id, paragraphs }: IPageSection) => {
  const background = {
    backgroundAttachment: 'scroll',
    backgroundColor: '#111',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }

  const headerHeight = backgroundImage
    ? { base: 300, md: 400, lg: 600 }
    : 200

  return (
    <Box as='section' {...background} textColor='white' textShadow='1px 1px #339' id={id} position='relative' pb={16}>
      <Flex
        position="relative"
        {...background}
        backgroundImage={`url(${backgroundImage})`}
        height={headerHeight}
        justify='center'
        align='flex-end'
      >
        { !!heading && <SectionHeading text={heading} /> }
      </Flex>

      <Container maxW="container.lg" py={8} px={0} height='100%'>
        { paragraphs && paragraphs.map((para, index) => (
            <Text key={index} p={4} m={4} backgroundColor='#11111199' fontSize={{ base: 'lg', md: 'xl' }}>{ para }</Text>
          ))
        }

        { fullWidth !== 'children' && children }
      </Container>

      { fullWidth == 'children' && children }
    </Box>
    )

}

export default PageSection
