import { Flex, Icon, Link } from "@chakra-ui/react"
import { FaDiscord, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa"

interface SocialIconsProps {
  discord?: string
  instagram?: string
  linkedin?: string
  twitter?: string
}

const SocialIcons = ({ discord, instagram, linkedin, twitter }: SocialIconsProps) => {
  return (
    <Flex wrap="wrap" justify="center">
      {twitter && <Link href={`https://twitter.com/${twitter}`} p={4}>
        <Icon as={FaTwitter} boxSize={8} color='white' />
      </Link>}

      {instagram && <Link href={`https://instagram.com/${instagram}`} p={4}>
        <Icon as={FaInstagram} boxSize={8} color='white' />
      </Link>}

      {discord && <Link href={`https://discord.gg/${discord}`} p={4}>
        <Icon as={FaDiscord} boxSize={8} color='white' />
      </Link>}

      {linkedin && <Link href={`https://linkedin.com/in/${linkedin}`} p={4}>
        <Icon as={FaLinkedin} boxSize={8} color='white' />
      </Link>}
    </Flex>
  )
}

export default SocialIcons
