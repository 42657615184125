import { Box, Button, Flex, Heading, Icon, Link } from '@chakra-ui/react'
import Image from 'next/image'
import NextLink from 'next/link'

import { useState } from 'react'

import { FaTwitter, FaInstagram, FaDiscord } from 'react-icons/fa'
import SocialIcons from '../SocialIcons/SocialIcons'

interface IHeroProps {
  title: string,
  titleImage?: string
}

const Hero = ({ title, titleImage }: IHeroProps) => {
  const [showTitle, setShowTitle] = useState(true)

  return (
    <Box minHeight="300px" p={4}>
      <Flex align='center' direction='column'>
        <Heading as="h1" visibility={!showTitle ? 'hidden' : 'visible'}>{title}</Heading>
        {titleImage && <Image src={titleImage} alt={title} quality={100} width='1200px' height='600px' onLoadingComplete={() => setShowTitle(false)} />}
      </Flex>

      <Flex align='center' justify='center' p={4}>
        <NextLink href='/forage' passHref>
          <Button as='a' fontSize="2xl" fontFamily='Brilon' px={6} py={8}>Forage!</Button>
        </NextLink>
      </Flex>

      <SocialIcons twitter='freakyfungi_nft' instagram='freakyfungi_nft' discord='freakyfungi' />
    </Box>
  )
}

export default Hero
