import { Avatar, Flex, Heading, Text } from '@chakra-ui/react'
import SocialIcons from '../SocialIcons/SocialIcons'

interface IProfile {
  description: string
  imgSrc?: string
  name: string
  role: string
  usernames?: { discord?: string, instagram?: string, linkedin?: string, twitter?: string }
}

const ProfileCard = ({ description, imgSrc = '', name, role, usernames }: IProfile) => {
  return (
    <Flex direction="column" align="center" p={4} maxW={420}>
      <Avatar name={name} src={imgSrc} size='4xl' bg='none'/>
      <Heading m={4}>{ role }</Heading>
      <Text textAlign='center'>{ description }</Text>
      <SocialIcons {...usernames} />
    </Flex>
  )
}

export default ProfileCard
