import { Box, Heading, Text } from '@chakra-ui/react'

interface ITimeLineItem {
  title: string
  paragraphs: string[]
}

const TimeLine = ({ items }: { items: ITimeLineItem[] }) => {
  return (
    <>
      { items.map((item, index) => (
        <Box key={index} p={4} m={2} backgroundColor='#11111199'>
          <Heading as='h3' mb={4}>{ item.title }</Heading>
          { item.paragraphs.map((para, pindex) => {
              return (
                <Text 
                  key={pindex} 
                  fontSize={{ base: 'lg', md: 'xl' }} 
                  pl={8}
                  borderLeft='4px solid white'
                >
                    { para }
                </Text>
              )
            })
          }
        </Box>
      ))}
    </>
  )
}

export default TimeLine
