import { Box, Flex } from '@chakra-ui/react'
import Image from 'next/image'

const ImageSlider = ({ images }) => {
  return (
    <Flex overflowX="auto" background='black'>
      { images.map((item, index) => <Box key={index} minW={{ base: '80vw', md: 420 }} m={8}>
        <Image src={item.src} alt={item.alt} width="420px" height="420px" />
      </Box>)}
    </Flex>
  )
}

export default ImageSlider
