import { Box, Flex, Heading } from '@chakra-ui/react'
import Head from 'next/head'

import Hero from '../components/Hero'
import PageSection from '../components/PageSection'
import ProfileCard from '../components/ProfileCard'

import content from './api/content/index.json'
import ImageSlider from '../components/ImageSlider'
import TimeLine from '../components/TimeLine'

const HomePage = () => {
  return (
    <>
      <Head>
        <title>FreakyFungi</title>

        <meta property="og:title" content="FreakyFungi - Putting the Fungi in Non-Fungible Tokens" />
        <meta property="og:description" content="The Freakiest Mycelial NFT Collection in the Metaverse" />
        <meta property="og:image" content="https://freakyfungi.io/freakyfungiHeroImage3840.png" />
        <meta property="og:url" content="https://freakyfungi.io" />

        <meta property="twitter:title" content="FreakyFungi - Putting the Fungi in Non-Fungible Tokens" />
        <meta property="twitter:description" content="The Freakiest Mycelial NFT Collection in the Metaverse" />
        <meta property="twitter:image" content="https://freakyfungi.io/freakyfungiHeroImage3840.png" />
        <meta name="twitter:image:alt" content="FreakyFungi Mushroom Text" />
      </Head>

      <Flex flex="1" direction="column">
        <Hero title="FreakyFungi" titleImage='/freakyfungiTransparent.png' />

        <PageSection
          id='intro'
          backgroundImage={content.intro.backgroundImage}
          heading="The Vision"
          paragraphs={content.intro.paragraphs}
        />

        <PageSection heading="The Manifestation" backgroundImage={content.artwork.backgroundImage} id='artwork' fullWidth="children" paragraphs={content.artwork.paragraphs}>
          <Heading p={4} textAlign='center'>Examples</Heading>
          <ImageSlider images={content.artwork.items} />
        </PageSection>

        <PageSection heading="The Way" backgroundImage={content.roadmap.backgroundImage} id='roadmap' paragraphs={content.roadmap.paragraphs}>
          <TimeLine items={content.roadmap.items} />
        </PageSection>

        <PageSection heading="The Minds" id='team' backgroundImage={content.team.backgroundImage}>
          <Flex justify="center" wrap="wrap">
            { content.team.items.map((member, index) => (
              <Box key={index} p={4} m={2} backgroundColor='#11111199'>
                <ProfileCard {...member} />
              </Box>
            ))}
          </Flex>
        </PageSection>
      </Flex>
    </>
  )
}

export default HomePage
